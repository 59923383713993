var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faq"},[_c('table',{staticClass:"rslt",staticStyle:{"border-left":"none"},attrs:{"cellspacing":"0"}},[_c('tbody',[_vm._l((_vm.faqs),function(result,i){return [_c('tr',{key:i,class:[i % 2 == 0 ? 'rslt row-even' : 'rslt row-odd']},[_c('td',{staticClass:"data-cell",staticStyle:{"padding":"3px"}},[_c('a',{attrs:{"target":"_blank","href":_vm.getDocumentLink(result.location, result.dropboxId)},on:{"click":function($event){_vm.selectedResult = result;
								_vm.logActivity(
									'FAQClicked',
									JSON.stringify({
										description: result.description,
										faq: _vm.getDocumentLink(result.location, result.dropboxId),
									})
								);}}},[_vm._v(" "+_vm._s(result.description)+" ")])])])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }