<template>
	<div class="faq">
		<table style="border-left: none" class="rslt" cellspacing="0">
			<tbody>
				<template v-for="(result, i) in faqs">
					<tr :key="i" :class="[i % 2 == 0 ? 'rslt row-even' : 'rslt row-odd']">
						<td style="padding: 3px" class="data-cell">
							<a
								target="_blank"
								:href="getDocumentLink(result.location, result.dropboxId)"
								@click="
									selectedResult = result;
									logActivity(
										'FAQClicked',
										JSON.stringify({
											description: result.description,
											faq: getDocumentLink(result.location, result.dropboxId),
										})
									);
								"
							>
								{{ result.description }}
							</a>
						</td>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "FAQ",
	data() {
		return {
			faqs: [],
		};
	},
	methods: {
		async getFaqs() {
			try {
				const res = await this.$http.get(`/api/faq/list-faqs`);
				res.data.faqResults.faqs ? (this.faqs = res.data.faqResults.faqs) : null;
			} catch (error) {
				console.log(error);
			}
		},
		getDocumentLink(fileLink, dropboxId) {
			if (fileLink) {
				let isFilePath = fileLink.startsWith("/");
				let parts = fileLink.split(".");
				let extension = parts.pop();
				let isPdf = extension.includes("pdf");
				let hasSpecifier = fileLink.includes("//");
				let uri = hasSpecifier ? fileLink : "//" + fileLink;
				return isFilePath
					? `/api/storage/${isPdf ? "preview" : "download"}-file?filePath=` +
							encodeURIComponent(fileLink) +
							"&dropboxId=" +
							dropboxId
					: uri;
			}
		},
		async logActivity(event, context) {
			let payload = {
				productId: null,
				area: "FAQ",
				event: event,
				searchContext: context,
			};

			try {
				await this.$http.post(`/api/search/log-activity`, payload);
			} catch (error) {
				console.error("Error logging search activity:", error);
			}
		},
	},
	mounted() {
		this.getFaqs();
	},
};
</script>

<style lang="scss" scoped>
.faq {
	table {
		tr {
			td {
				a {
					color: #003399;
				}
			}
		}
	}
}
</style>
